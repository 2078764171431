<template>
  <div class="pa-9">
    <h1>Tạo sản phẩm đính kèm</h1>
    <ValidationObserver ref="add_deal">
      <h4>Chọn khóa học</h4>
      <ValidationProvider
          name="course_id"
          rules="required"
          v-slot="{ errors }"
      >
        <v-autocomplete
            outlined
            dense
            :name="'course_id'"
            :items="courses"
            :item-text="'title'"
            disabled
            :item-value="'id'"
            v-model="course_id"
            :placeholder="'Chọn khóa học'"
        />
        <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <v-row>
        <v-col cols="6">
          <h4>Loại sản phẩm đính kèm</h4>
          <ValidationProvider
              name="course_id"
              rules="required"
              v-slot="{ errors }"
          >
            <v-autocomplete
                outlined
                dense
                :name="'type'"
                :items="types"
                :item-text="'title'"
                :item-value="'id'"
                @change="changeType"
                v-model="type"
                :placeholder="'Chọn loại sản phẩm'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <h4>Chọn sản phẩm</h4>
          <ValidationProvider
              name="course_id"
              rules="required"
              v-slot="{ errors }"
          >
            <v-autocomplete
                outlined
                dense
                :name="'reference_id'"
                :items="references"
                :item-text="'title'"
                :item-value="'id'"
                @change="changeProduct"
                v-model="reference_id"
                :placeholder="'Chọn sản phẩm'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h4>Giá tổng sản phẩm đính kèm</h4>
          <ValidationProvider name="price" rules="required|number" v-slot="{ errors }">
            <vuetify-money
                v-model="price"
                v-bind:label="''"
                v-bind:placeholder="'Nhập giá tổng sản phẩm đính kèm'"
                v-bind:readonly="false"
                v-bind:dense="true"
                v-bind:disabled="false"
                v-bind:outlined="true"
                v-bind:clearable="true"
                v-bind:valueWhenIsEmpty="''"
                v-bind:options="options"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] ? 'Giá tổng sản phẩm đính kèm không được để trống' : '' }}
            </span>
          </ValidationProvider>
        </v-col>
        <v-col cols="6" v-if="type ==  1">
          <h4>Số lượng</h4>
          <ValidationProvider
              name="number"
              rules="required"
              v-slot="{ errors }"
          >
            <v-text-field
                outlined
                dense
                :name="'title'"
                type="number"
                v-model="number"
                :placeholder="'Nhập số lượng'"
            />
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-row class="d-flex align-center justify-center my-9">
      <v-col cols="5" class="d-flex align-center justify-center text-center">
        <HnrButton color="primary"
                   width="200px"
                   @hnrClickEvent="addProduct"
                   class="text-body-1" :text="'Thêm sản phầm đi kèm'"/>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="products"
        v-if="products && products.length > 0"
    >
      <template v-slot:item.url="{ item }">
        <v-img
            :src="item.url"
            width="200px"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.price="{ item }">
        {{ formatPrice(item.price) }}đ
      </template>
      <template v-slot:item.price_root="{ item }">
        {{ formatPrice(item.price_root) }}đ
      </template>
    </v-data-table>
    <v-row class="d-flex align-center justify-center my-9" v-if="products && products.length > 0">
      <v-col cols="5" class="d-flex align-center justify-center text-center">
        <HnrButton color="primary"
                   width="100px"
                   @hnrClickEvent="createDeal"
                   class="text-body-1" :text="'Lưu'"/>
      </v-col>
    </v-row>
  </div>

</template>
<script>
import {SAVE_DEAL_COURSE} from "@/store/dealCourses.module";
import {GET_LIST_COURSE} from "@/store/courses.module";
import {GET_DETAIL_DEAL_COURSE} from "@/store/dealCourses.module";
import {
  GET_LIST_NOY_BUY_BOOK
} from "@/store/book.module";
import {
  GET_LIST_COMBO
} from "@/store/combo-course.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      deal: {},
      types: [
        {id: 1, title: 'Sách'},
        {id: 2, title: 'Khóa học 1-1'},
      ],
      options: {
        locale: "pt-BR",
        prefix: "VNĐ",
        suffix: "",
        length: 11,
        precision: 0,
      },
      references: [],
      type: 1,
      course_id: 1,
      reference_id: null,
      number: 1,
      price: 0,
      products: [],
      name: '',
      url: '',
      slug: '',
      price_root: '',
      headers: [
        {text: "Tên sản phẩm", value: "name", align: 'center'},
        {text: "Ảnh", value: "url", align: 'center'},
        {text: "Số lượng", value: "number", align: 'center'},
        {text: "Giá cũ", value: "price_root", align: 'center'},
        {text: "Giá mới", value: "price", align: 'center'},
        {text: "Hành động", value: "actions", sortable: false}
      ]
    }
  },
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  async created() {
    this.deal.course_id = parseInt(this.$route.query.id)
    this.course_id = parseInt(this.$route.query.id)
    await this.$store.dispatch(GET_LIST_COURSE);
    await this.$store.dispatch(GET_LIST_NOY_BUY_BOOK);
    await this.$store.dispatch(GET_DETAIL_DEAL_COURSE, {course_id: this.$route.query.id});
    if (this.DealCourse) {
      this.deal = Object.assign({}, this.DealCourse)
      this.products = [...this.DealCourse.product]
    }
  },
  computed: {
    ...mapGetters({
      courses: 'listCourse',
      books: "booksNotBuy",
      combos: "combos",
      DealCourse: "DealCourseDetail"
    }),
  },
  watch: {
    books(newVal) {
      this.references = newVal
    },
    combos(newVal) {
      this.references = newVal
    }
  },
  methods: {
    addProduct() {
      if (!this.type) {
        this.$toasted.error('Bạn chưa chọn loại sản phẩm', {
          position: "top-right",
          duration: 1000
        });
        return false
      }
      if (!this.reference_id) {
        this.$toasted.error('Bạn chưa chọn sản phẩm', {
          position: "top-right",
          duration: 1000
        });
        return false
      }
      if (this.type == 2) {
        this.number = 1
      }
      if (!this.number) {
        this.$toasted.error('Bạn chưa nhập số lương sản phẩm đi kèm', {
          position: "top-right",
          duration: 1000
        });
        return false
      }
      if (!this.price) {
        this.$toasted.error('Bạn chưa nhập tổng giá sản phẩm đi kèm', {
          position: "top-right",
          duration: 1000
        });
        return false
      }
      let total_price = parseInt(this.price)
      let total_price_root = parseInt(this.price_root) * parseInt(this.number)
      if (total_price >= total_price_root) {
        this.$toasted.error('giá deal quá cao so với giá hiện tại !!', {
          position: "top-right",
          duration: 1000
        });
        return false
      }
      let is_add = true
      this.products.map(product => {
        if (this.reference_id == product.reference_id && this.type == product.type) {
          product.number = parseInt(product.number) + parseInt(this.number)
          product.price = parseInt(product.price) + parseInt(this.price)
          product.price_root = parseInt(this.price_root) * parseInt(product.number)
          is_add = false
        }
      })
      if (is_add) {
        let item = {
          type: this.type,
          reference_id: this.reference_id,
          number: this.number,
          price: this.price,
          name: this.name,
          url: this.url,
          price_root: parseInt(this.price_root) * parseInt(this.number),
          slug_reference: this.slug_reference,
          course_id: this.deal.course_id
        }
        this.products.push(item)
      }
      this.resetProduct()
    },
    changeProduct(val) {
      if (this.type == 1) {
        if (this.books && this.books.length > 0)
          this.books.map(item => {
            if (val == item.id) {
              this.name = item.title
              this.url = item.url
              this.price_root = item.sale ? item.amount : item.price
              this.slug_reference = item.slug
            }
          })
      }
      if (this.type == 2) {
        if (this.combos && this.combos.length > 0)
          this.combos.map(item => {
            if (val == item.id) {
              this.name = item.title
              this.url = null
              this.price_root = item.sale ? item.amount : item.price
              this.slug_reference = item.slug
            }
          })
      }
    },
    async changeType(val) {
      this.reference_id = null
      if (val == 1) {
        await this.$store.dispatch(GET_LIST_NOY_BUY_BOOK);
      }
      if (val == 2) {
        await this.$store.dispatch(GET_LIST_COMBO);
      }
    },
    deleteItem(item) {
      if (this.products.length == 1) {
        this.products = []
      } else {
        this.products = this.products.filter((e) => {
          return e.slug_reference !== item.slug_reference
        })
      }
    },
    formatPrice(price) {
      let data_price = parseFloat(price)
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
    resetProduct() {
      this.type = 1
      this.reference_id = 1
      this.number = 1
      this.price = 0
      this.name = ''
      this.url = ''
      this.price_root = ''
    },
    createDeal() {
      if (this.products && this.products.length > 0) {
        this.deal.product = this.products
        this.$store.dispatch(SAVE_DEAL_COURSE, this.deal).then(data => {
          if (data.status) {
            this.$toasted.success('thêm deal sốc thành công !!', {
              position: "top-right",
              duration: 3000
            })
            this.$router.push('/deal/list')
          }
        })
      } else {
        this.$toasted.error('bạn chưa chọn sản phầm đi kèm !!', {
          position: "top-right",
          duration: 3000
        })
      }
    }
  }
}
</script>